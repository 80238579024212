/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'checked': {
    width: 14,
    height: 11,
    viewBox: '0 0 14 11',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 11L0 6l1.4-1.4L5 8.2 12.6.6 14 2l-9 9z" _fill="#FFF"/>'
  }
})
